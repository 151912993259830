.cell {
    padding: 7px 5px !important;
    text-align: center !important;
}

.headWeek {
    border: 1px solid transparent !important;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc !important;
    background-color: #fff;
}

.bodyRow {
    border-color: transparent !important;
}

.bodyCell {
    color: #000 !important;
}

#today {
    color: #f00 !important;
    font-weight: bold !important;
}

#realeaseHistory {
    height: 350px !important;
    max-height: 350px !important;
    overflow-y: scroll;
}

#btnToday button {
    color: #00e6cf;
    font-weight: bold;
}


.btnDay {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

    .btnDay:hover {
        border-color:#00e6cf;
    }

    #dateSelected {
        background-color: #00e6cf !important;
        border-color: #00e6cf !important;
        color: #fff !important;
        font-weight: bold !important;
    }


.badgeReceive {
    width: 5px;
    height: 5px;
    margin: 0 1px;
    background-color: green;
    border-radius: 50px;
}

.badgePay {
    width: 5px;
    height: 5px;
    margin: 0 1px;
    background-color: red;
    border-radius: 50px;
}

.badgeStatus {
    width: 5px;
    height: 5px;
    margin: 0 1px;
    background-color: blue;
    border-radius: 50px;
}