#kt_subheader {
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}

.newProductWrapper .inputSmaller .MuiInputBase-formControl {
    height: 36.8px;
}

.newProductWrapper .grid-small {
    height: 80px;
}

.newProductWrapper .inputSmaller .MuiInputLabel-formControl.MuiInputLabel-animated {
    line-height: 0;
}

.newProductWrapper .MuiGrid-root.MuiGrid-item {
    padding: 3px 12px;
}

.newProductWrapper .card-body-close {
    padding: 0!important;
    margin: 0!important;
}

#payments_wrapper table th, #payments_wrapper table td{
    padding: 0.2rem 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    font-size: 12px;
}

.pickup-info-button {
    cursor: pointer;
}

.pickup-info-button:before {
    font-size: 20px;
    font-weight: bolder!important;
    color: blue;
}

.border-error{
    border: 1px solid #f64e60!important;
    border-radius: 6px!important;
}

.MuiPaper-elevation1{
    overflow: auto;
}

.MuiTable-stickyHeader {
    position: sticky !important;
}

.MuiTableCell-head:not(.modal .MuiTableCell-head, .wrap .MuiTableCell-head) {
    white-space: nowrap;
}

.MuiTableCell-body:not(.modal .MuiTableCell-body, .wrap .MuiTableCell-body) {
    white-space: nowrap;
}

.hover {
    cursor: pointer !important;
}

.hover:hover {
    background: rgba(200, 200, 200, 0.3) !important;
    transition: all 0.3s ease !important;
}

.hover-drop-box * {
    pointer-events: none !important;
}

.hover-drop-box:hover {
    transform: scale(110%) !important;
    transition: transform 0.2s ease-in-out !important;
}

.active-hover-drop-box {
    background: rgba(200, 200, 200, 0.3) !important;
    transition: background-color 0.3s ease !important;

    transform: scale(110%) !important;
    transition: transform 0.2s ease-in-out !important;
}

.btn-blue {
    background: #6993ff !important;
    border: none !important;
}

.btn-green {
    background: #50cd89 !important;
    border: none !important;
}

.dropdown-toggle {
    padding-left: 8px !important; 
    padding-right: 8px !important; 
    display: flex !important;
    margin: auto;
}

.dropdown-toggle::after {
    display: none !important; 
}

.dropdown-menu {
    padding: 5px 5px 5px 10px !important;
    min-width: 0px !important;
}

.collapse-arrow-down {
    transform: rotate(0deg) !important;
    transition: transform 0.2s ease-in !important;
}

.collapse-arrow-up {
    padding: 0 !important;
    margin: 0 !important;
    transform: rotate(180deg) !important;
    transition: transform 0.2s ease-in !important;
}

.text-green {
    color: green !important;
}

.wide-modal {
    width: 90vw !important;
    max-width: 90vw !important;
} 